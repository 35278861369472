<template>
  <div class="ExamMatchTheCandidate">
    <detailsHeader
      :router-name="' / 考生管理 / 对应考生'"
      :router-url="'unionExamNewStudent'"
      :query="routerData"
    >
      <el-button
        v-if="$route.query.id"
        type="primary"
        :loading="btnLoading"
        @click="getexamstudentbyid(2)"
        >跳过</el-button
      >
    </detailsHeader>
    <div class="table-box">
      <div class="student-box">
        <p>待确认考生信息</p>
        <div class="item-cnt-box">
          <div class="item-box">
            <div class="label">姓 名</div>
            <div>{{ examStudent.studentName }}</div>
          </div>
          <div class="item-box">
            <div class="label">学 校</div>
            <div>{{ examStudent.schoolName }}</div>
          </div>
          <div class="item-box">
            <div class="label">学 籍 号</div>
            <div>{{ examStudent.studentNo }}</div>
          </div>
          <div class="item-box">
            <div class="label">考 号</div>
            <div>{{ examStudent.studentExamId }}</div>
          </div>
          <div v-if="examStudent.combination" class="item-box">
            <div class="label">选考组合</div>
            <div>{{ examStudent.combination }}</div>
          </div>
          <div class="item-box">
            <div class="label">班 级</div>
            <div>{{ examStudent.classNum }}</div>
          </div>
          <div class="item-box">
            <div class="label">对应状态</div>
            <div
              class="item-box-tips"
              :class="{
                succ: examStudent.isMatching == 0,
                err: examStudent.isMatching == 1 && examStudent.matchAgain != 1,
                warning:
                  examStudent.isMatching == 1 && examStudent.matchAgain == 1,
              }"
            >
              <!-- {{ scope.row.isMatching | setName(matchingList) }} -->
              <template v-if="examStudent.isMatching == -1">待匹配</template>
              <template v-if="examStudent.isMatching == 0">成功</template>
              <template v-if="examStudent.isMatching == 1">
                <template v-if="examStudent.matchAgain == 1">质疑</template>
                <template v-else>失败</template>
              </template>
              <template v-if="examStudent.isMatching == 2">有质疑</template>
            </div>
          </div>
          <div class="item-box">
            <template v-if="showBtn">
              <el-button
                v-if="examStudent.isMatching == 1 && roleId == 1"
                type="primary"
                size="mini"
                @click="operateExamStudent(examStudent, 0)"
              >
                添加考生至学生
              </el-button>
              <el-button
                v-if="indexStudent.id"
                :disabled="examInformation.status != 1"
                size="mini"
                @click="deleteforpassword(examStudent.studentId, 1)"
              >
                删除
              </el-button>
            </template>
          </div>
        </div>
      </div>
      <div class="student-list">
        <p>
          选择需要对应的考生
          <el-button
            v-if="$route.query.id"
            type="primary"
            :loading="btnLoading"
            @click="getexamstudentbyid(2)"
          >
            下一考生
          </el-button>
        </p>
        <div class="student-list-box">
          <div class="search-box">
            <!-- <el-select
              v-model="schoolId"
              filterable
              placeholder="请选择"
              @change="handleClick"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option
                v-for="(item, i) in schoolList"
                :key="i"
                :label="item.schoolName"
                :value="item.schoolId"
              >
              </el-option>
            </el-select> -->

            <el-input
              v-model="studentName"
              placeholder="请输入内容"
              @keyup.enter.native="getschoolstudentlist"
            ></el-input>
            <el-button type="primary" @click="getschoolstudentlist"
              >搜索</el-button
            >
          </div>
          <p>基础信息</p>
          <el-table
            v-loading="loading"
            :data="studentList"
            border
            style="width: 100%"
          >
            <el-table-column
              align="center"
              prop="studentNameSchool"
              label="学生姓名"
              width="120"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="schoolNameSchool"
              label="学生学校"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="gradeNameSchool"
              label="学生年级"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="classNumSchool"
              label="学生班级"
            >
            </el-table-column>
            <el-table-column align="center" prop="userName" label="用户名">
            </el-table-column>
            <el-table-column prop="address" label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.isMatching === null"
                  type="text"
                  class="warning"
                  @click="useStudent(scope.row)"
                >
                  对应该学生
                </el-button>
                <template v-else>-</template>
              </template>
            </el-table-column>
          </el-table>
          <no-data
            v-if="studentList.length == 0 && loading == false"
            message="暂无考生信息"
          ></no-data>
        </div>
      </div>
    </div>

    <!-- 考生添加提示 -->
    <el-dialog title="提示" :visible.sync="studentDialogVisible" width="372px">
      <div>
        <div class="tips">
          若考号固定，则需要勾选 同步！<br />
          若考号不固定，则无需勾选！
        </div>
        正在添加考生“{{ indexSetStudent.studentName }}”到学生信息，是否继续？
        <div style="margin-top: 18px">
          <el-checkbox v-model="studentExamId"> 同步考生考号？ </el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="studentDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="examIdLoading" @click="setExam()">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 删除单个考生 -->
    <el-dialog title="温馨提示" :visible.sync="delPassword1" width="400px">
      <div>
        此操作将会
        <span class="err">{{ errPassMsg }}</span>
        ，是否继续？
      </div>
      <div class="password">
        <span>请输入登录密码：</span>
        <el-input
          v-model="password"
          autocomplete="new-password"
          placeholder="请输入登录密码"
          show-password
          @keyup.enter.native="monitorBackStu"
        ></el-input>
      </div>
      <!-- <div class="err">{{ errPassMsg }}</div> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="delPassword1 = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="modelLoading"
          @click="monitorBackStu()"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { matchingList } from "@/core/util/constdata";
import { getObj, schoollistbyexamid } from "@/core/api/exam/exam";

import {
  getexamstudentbyid,
  getschoolstudent,
  getschoolstudentlist,
  reopenedtostudent,
  getclassandexamroom,
  fetchList,
  failtostudent,
  deleteforpassword,
} from "@/core/api/exam/examstudent";

import { encryption } from "@/core/util/util";
import { getStore } from "@/core/util/store";
import detailsHeader from "@/components/detailsHeader.vue";
export default {
  name: "ExamMatchTheCandidate",
  components: {
    detailsHeader,
  },
  data() {
    return {
      delPassword1: false,
      errPassMsg: "",
      roleId: "",

      examInformation: {
        name: "",
      },
      indexStudent: {},
      examStudent: {},
      examStudentList: [],
      succStudentList: [],
      matchingList: matchingList,
      classNumList: [],
      classNum: "",
      studentName: "",
      studentList: [],
      loading: false,
      btnLoading: false,
      studentDialogVisible: false,
      pageIndex: 1,
      pageSize: 5,
      total: 0,
      routerData: {},
      indexSetStudent: {},
      studentExamId: true,
      examIdLoading: false,
      password: "",
      modelLoading: false,
      showBtn: false,
      schoolList: [],
      schoolId: "",
    };
  },
  async created() {
    this.getObj();
    await this.getExamSchools();
    this.routerData = Object.assign({}, this.$route.query);
    delete this.routerData.id;
    if (this.$route.query.id) {
      this.getexamstudentbyid(1);
    } else {
      this.fetchList();
    }
  },
  methods: {
    async getExamSchools() {
      const res = await schoollistbyexamid({
        examId: this.$route.query.examId,
      });
      this.schoolList = res.data.data;
    },
    monitorBackStu() {
      if (!this.password) {
        this.$message({
          message: "请输入登录密码",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let userInfo = {
        randomStr: "blockPuzzle",
        code: "xxx",
        password: this.password,
      };
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });

      this.failtostudent(user.password);
    },
    check(row) {
      this.indexStudent = row;
      this.$nextTick(() => {
        this.examStudentList.forEach((item) => {
          item.checked = false;
          if (item.id == row.id) {
            item.checked = true;
          }
        });
      });
    },
    operateExamStudent(item, type) {
      let str;
      if (type == 1) {
        str = `确定删除考生‘${item.studentName}’，删除后不可恢复，是否继续？`;
      } else {
        this.indexSetStudent = item;
        // str = `确定添加考生‘${item.studentName}’到学生信息，是否继续？`;
        this.studentDialogVisible = true;
        return;
      }
      this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.indexStudent = item;
          this.failtostudent(type);
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消操作",
          // });
        });
    },
    setExam() {
      // this.indexStudent = item;
      this.failtostudent(this.studentExamId ? 0 : 2);
    },
    // 0-添加,1-删除
    failtostudent(type) {
      let data = {
        id: this.indexStudent.id,
        type: type,
      };
      this.examIdLoading = true;
      failtostudent(data)
        .then(() => {
          //清除vuex中的缓存
          this.$store.commit("delKeepArr");
          this.indexStudent = {};
          this.studentDialogVisible = false;
          this.examIdLoading = false;
          this.showBtn = false;
          this.$message({
            type: "success",
            message: "操作成功!",
            showClose: true,
          });
          if (this.$route.query.id) {
            this.getexamstudentbyid(2);
          } else {
            this.fetchList();
          }
        })
        .catch(() => {
          this.examIdLoading = false;
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.fetchList();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.fetchList();
      // console.log(`当前页: ${val}`);
    },
    fetchList() {
      let data = {
        current: this.pageIndex,
        size: this.pageSize,
        examId: this.$route.query.examId,
        information: 1,
      };
      fetchList(data).then((res) => {
        // console.log(res);
        // checked;
        this.examStudentList = [];
        res.data.data.records.forEach((item) => {
          item.checked = false;
          this.examStudentList.push(item);
        });
        this.total = res.data.data.total;
        // this.indexStudent = {};
      });
    },
    reopenedtostudent(data) {
      reopenedtostudent(data)
        .then(() => {
          this.$message({
            type: "success",
            message: "指定成功!",
          });
          //清除vuex中的缓存
          this.$store.commit("delKeepArr");
          this.indexStudent = {};
          if (this.$route.query.id) {
            this.getexamstudentbyid(1);
          } else {
            this.fetchList();
          }
          this.getschoolstudentlist();
          //
        })
        .catch(() => {
          //
        });
    },
    useStudent(item) {
      if (!this.indexStudent.id) {
        this.$message({
          type: "warning",
          message: "请选择需要处理的考生",
        });
        return;
      }
      let name = item.studentNameSchool || item.name;
      let str = `将考生‘${this.indexStudent.studentName}’指定为学生‘${name}’是否继续?`;
      this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            studentId: item.studentId,
            id: this.indexStudent.id,
          };
          this.reopenedtostudent(data);
          //清除vuex中的缓存
          this.$store.commit("delKeepArr");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消指定",
          });
        });
    },
    handleClick() {
      this.getschoolstudentlist();
    },
    getschoolstudentlist() {
      let data = {
        examId: this.$route.query.examId,
        schoolId: this.schoolId,
        studentName: this.studentName,
      };
      this.loading = true;
      getschoolstudentlist(data)
        .then((res) => {
          this.studentList = res.data.data;
          this.loading = false;
          // console.log(res);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getclassandexamroom() {
      getclassandexamroom(this.$route.query.examId).then((res) => {
        this.classNumList = [{ value: "", label: "全部" }];
        res.data.data.classNums.forEach((index) => {
          this.classNumList.push({
            value: index,
            label: index,
          });
        });
      });
    },
    deleteforpassword(studentId) {
      let data = {
        examId: this.$route.query.examId,
        studentId: studentId,
      };

      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        message: h("p", null, [
          h("span", null, "当前正在删除考生 "),
          h("span", { style: "color: red" }, `${this.examStudent.studentName}`),
          h("span", null, "删除后不可恢复，是否继续？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteforpassword(data).then((res) => {
            // console.log(res);
            // 需要输入密码
            this.errPassMsg = "";
            if (res.data.data == 0) {
              this.delPassword1 = true;
              this.errPassMsg = res.data.msg;
            }
            // 不需要输入密码
            if (res.data.data == 1) {
              this.failtostudent(1);
              // this.clearByCondition(1);
            }
          });
        })
        .catch(() => {});
    },
    // 获取考生列表
    getexamstudentbyid(type) {
      let data = {
        id: this.$route.query.id,
        type: type,
        examId: this.$route.query.examId,
      };
      this.btnLoading = true;
      getexamstudentbyid(data)
        .then((res) => {
          this.btnLoading = false;
          this.showBtn = true;
          // this.examStudentList = [];
          if (!res.data.data.id) {
            this.examStudent = {};
            const h = this.$createElement;
            this.$msgbox({
              title: "温馨提示",
              message: h("p", null, [
                h("span", null, "当前已处理完毕异常考生，是否返回考生列表页？"),
              ]),
              showCancelButton: true,
              confirmButtonText: "确定",
              cancelButtonText: "取消",
            })
              .then(() => {
                this.$router.push({
                  name: "unionExamNewStudent",
                  query: this.routerData,
                });
              })
              .catch(() => {});
            return;
          }
          this.examStudent = res.data.data;
          this.indexStudent = res.data.data;
          this.schoolId = this.indexStudent.schoolId;
          this.$router.push({
            query: {
              id: this.indexStudent.id,
              examId: this.$route.query.examId,
            },
          });
          if (res.data.data.isMatching == 0) {
            // this.studentName = res.data.data.studentName;
            this.getschoolstudent();
          } else {
            this.studentName = res.data.data.studentName;
            this.getschoolstudentlist();
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 已对应的学生
    getschoolstudent() {
      if (!this.indexStudent.id) {
        this.succStudentList = [];
        return;
      }
      let data = {
        id: this.$route.query.id,
      };
      getschoolstudent(data).then((res) => {
        this.succStudentList = [];
        this.succStudentList.push(res.data.data);
      });
    },
    //获取考试基本信息
    getObj() {
      let { roleId } = getStore({ name: "userInfo" });
      this.roleId = roleId;
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ExamMatchTheCandidate {
  margin: 24px 0 64px;
  ::v-deep .el-table__empty-block {
    display: none;
  }
  .password {
    margin-top: 12px;
    display: flex;
    align-items: center;
    span {
      flex-shrink: 0;
    }
  }
  .tips {
    padding: 12px;
    background: #fdf0ee;
    border: 1px dashed #ff7575;
    color: #777473;
    // margin-top: 8px;
    margin-bottom: 18px;
    span {
      color: #e48469;
      margin-right: 4px;
      &:first-child {
        padding: 0px 6px;
        font-size: 12px;
        border-radius: 50%;
        background-color: #f17e5e;
        color: #ffffff;
        letter-spacing: 0;
      }
    }
  }
  .item-box-tips {
    position: relative;
    padding-left: 10px;
    &::after {
      position: absolute;
      content: "";
      left: 0;
      top: 7px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }
  .succ {
    color: $primary-color;
    &::after {
      background: $primary-color;
    }
  }
  .warning {
    color: #fd6919;
    &::after {
      background: #fd6919;
    }
  }
  .err {
    color: #f56c6b;
    &::after {
      background: #f56c6b;
    }
  }
  .table-box {
    padding: 18px;
    background-color: #ffffff;
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    .student-box {
      width: 250px;
      .item-cnt-box {
        border-radius: 4px;
        border: 1px solid #ebecee;
      }
      .item-box {
        border-bottom: 1px solid #ebecee;
        padding: 18px;
        display: flex;
        .label {
          width: 62px;
          margin-right: 23px;
          text-align-last: justify;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
    .student-list {
      width: calc(100% - 268px);
      > p {
        display: flex;
        justify-content: space-between;
        align-content: center;
      }
    }
    .el-pagination {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 18px;
    }
    .search-box {
      margin-bottom: 18px;
      display: flex;
      .el-select {
        width: 112px;
      }
      .el-button {
        margin-left: 8px;
      }
      .el-input {
        width: 198px;
        margin-left: 8px;
      }
    }
    .el-table {
      margin-bottom: 24px;
    }

    .table-title {
      color: #101011;
      margin-bottom: 24px;
    }
  }
}
</style>
